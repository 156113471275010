import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './bav.css';

function Bav() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="bav-wrapper">
            <nav className="bav">
                <div className="bav-title">Brad Yip</div>
                <div className={`bav-menu active`}>
                    <ul className="bav-list">
                        <li className="bav-item">
                            <NavLink to="/portfolio" activeClassName="active">Portfolio</NavLink>
                        </li>
                        <li className="bav-item">
                            <NavLink to="/about" activeClassName="active">About</NavLink>
                        </li>
                        <li className="bav-item">
                            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=bradyip123@gmail.com" target="_blank" activeClassName="active">
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
                {/* <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div> */}
            </nav>
        </div>
    );
}

export default Bav;
