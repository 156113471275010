import wolf1 from "../../assets/wolf/brx-yip-character-sheet.jpg"
import wolf2 from "../../assets/wolf/brx-yip-clownpass.jpg"
import wolf3 from "../../assets/wolf/brx-yip-illustration.jpg"
import wolf4 from "../../assets/wolf/brx-yip-iterations-presentation.jpg"
import wolf5 from "../../assets/wolf/brx-yip-orthographic.jpg"
import wolf6 from "../../assets/wolf/presentation.mp4"
import wolf7 from "../../assets/wolf/weapon-presentation.mp4"
import "./pieces.css"
import React, { useRef } from 'react';

const Wolf = () => {
    const videoRef = useRef(null);

    const handleCanPlay = () => {
        videoRef.current.play();
    };

    return (
        <div className="project-wrapper">
            <div className="project-container">
                <img src={wolf3} className="project-img-vert" alt="artwork" />
                <p className="proj-caption"> Title Card</p>
                <video className="proj-video" autoPlay loop muted onCanPlayThrough={handleCanPlay} ref={videoRef}>
                    <source src={wolf6} type="video/mp4" />
                </video>

                <img src={wolf1} className="project-img" alt="artwork" />
                <p className="proj-caption"> Concept Sheet</p>

                <img src={wolf5} className="project-img" alt="artwork" />
                <p className="proj-caption"> Orthographic</p>
                <img src={wolf2} className="project-img" alt="artwork" />

                <img src={wolf4} className="project-img" alt="artwork" />
                <p className="proj-caption"> Iteration Sketches for Style and Tone</p>
                <video className="proj-video" autoPlay loop muted onCanPlayThrough={handleCanPlay} ref={videoRef}>
                    <source src={wolf7} type="video/mp4" />
                </video>
                <p className="proj-caption">Rifle Sketches</p>
            </div>
        </div>
    )
}

export default Wolf
