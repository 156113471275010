import "./about.css"
import twitter from "../../assets/twitterx.svg"
import instagram from "../../assets/instagram.svg"
import artstation from "../../assets/artstation.svg"
const About = () => {

    return (
        <div className="about-wrapper">
            <p className="about-p">Brad Yip is concept artist from New Zealand working in games.</p>
            <p className="about-p">Originally from Auckland, he studied at Victoria University in Wellington, and briefly attended FZD.</p>
            <p className="about-p">He is open to trying new things. Feel free to contact me for any inquiries.</p>
            <p className="about-p-small"><em>📧bradyip123@gmail.com</em></p>
            <div className='social-wrapper'>
                {/* <div className='social-media'>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} className='social-svg' alt="twitter" />
                    </a>
                </div> */}
                <div className='social-media'>
                    <a href="https://www.instagram.com/___boyart/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} className='social-svg' alt="instagram" />
                    </a>
                </div>
                <div className='social-media'>
                    <a href="https://bradyip.artstation.com/" target="_blank" rel="noopener noreferrer">
                        <img src={artstation} className='social-svg' alt="artstation" />
                    </a>
                </div>
            </div>
        </div>
    )
}


export default About
