import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileButton from './ProfileButton';
import { Link } from 'react-router-dom';

import './Navigation.css';

function Navigation({ isLoaded }) {
	const sessionUser = useSelector(state => state.session.user);
	const [portfolio, setPortfolio] = useState("PORTFOLIO")
	const [contact, setContact] = useState("CONTACT")
	const [blog, setBlog] = useState("BLOG")
	const [about, setAbout] = useState("ABOUT")

	return (

		<div className='navigation-wrapper'>
			<div className='nav-link-container'>
				<h1 className='nav-header'>BRAD YIP</h1>
				{/* <li className='nav-comp'>
				<NavLink exact to="/" className="nav-links">HOME</NavLink>
			</li> */}

				<div className='nav-comp'
					onMouseEnter={() => { setPortfolio("PORTFOLIO ◀") }}
					onMouseLeave={() => { setPortfolio("PORTFOLIO") }}
				>
					<NavLink exact to="/portfolio" className="nav-links">{portfolio}</NavLink>
				</div>
				<div className='nav-comp'
					onMouseEnter={() => { setContact("CONTACT ◀") }}
					onMouseLeave={() => { setContact("CONTACT") }}
				>
					<a href="https://mail.google.com/mail/?view=cm&fs=1&to=bradyip123@gmail.com" target="_blank" className="nav-links">
						{contact}
					</a>
				</div>

				{/* <div className='nav-comp'
						onMouseEnter={() => { setBlog("BLOG ◀") }}
						onMouseLeave={() => { setBlog("BLOG") }}
					>
						<NavLink exact to="/blog" className="nav-links">{blog}</NavLink>
					</div> */}

				<div className='nav-comp'
					onMouseEnter={() => { setAbout("ABOUT ◀") }}
					onMouseLeave={() => { setAbout("ABOUT") }}>
					<NavLink exact to="/about" className="nav-links">{about}</NavLink>
				</div>
			</div>
			{/* {isLoaded && (
				<li>
					Admin Login
					<ProfileButton user={sessionUser} />
				</li>
			)} */}


		</div>


	);
}

export default Navigation;
