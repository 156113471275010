
import wulf1 from "../../assets/wolf-pt2/20231024_2306_Wolf_part_2_WIP.jpg"
import wulf2 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP_1.jpg"
import wulf3 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP_2.jpg"
import wulf4 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP_3.jpg"
import wulf5 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP_4.jpg"
import wulf6 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP.jpg"
import wulf7 from "../../assets/wolf-pt2/ConceptSheet.png"
import wulf8 from "../../assets/wolf-pt2/iterations.png"
import wulf9 from "../../assets/wolf-pt2/MovementStudies.png"
import wulf10 from "../../assets/wolf-pt2/Orthographic.png"
import wulf11 from "../../assets/wolf-pt2/Pitchartpage.png"
import "./pieces.css"

const Wulf = () => {
    return (
        <div className="project-wrapper">
            <div className="project-container">
                <img src={wulf1} className="project-img" alt="wulf 1" />
                <img src={wulf2} className="project-img" alt="wulf 2" />
                <p className="proj-caption"> Character Sheet WIP</p>
                <img src={wulf3} className="project-img" alt="wulf 3" />
                <p className="proj-caption"> Ortho 1 WIP</p>
                <img src={wulf4} className="project-img" alt="wulf 4" />
                <p className="proj-caption"> Ortho 2 WIP</p>
                <img src={wulf5} className="project-img" alt="wulf 5" />
                <p className="proj-caption"> Movement Studies/Thematic Studies</p>
                <img src={wulf6} className="project-img" alt="wulf 6" />
                <p className="proj-caption"> Iterations</p>
                <img src={wulf7} className="project-img" alt="wulf 7" />
                <img src={wulf8} className="project-img" alt="wulf 8" />
                <img src={wulf9} className="project-img" alt="wulf 9" />
                <img src={wulf10} className="project-img" alt="wulf 10" />
                <img src={wulf11} className="project-img" alt="wulf 11" />


            </div>
        </div>
    )
}

export default Wulf
