import bloss1 from "../../assets/spiritBloss/brx-yip-chromas-presentation.jpg"
import bloss2 from "../../assets/spiritBloss/brx-yip-exploration-presentation-01test.jpg"
import bloss3 from "../../assets/spiritBloss/brx-yip-exploration-presentation-02.jpg"
import bloss4 from "../../assets/spiritBloss/brx-yip-hero-shot-refined-concept.jpg"
import bloss5 from "../../assets/spiritBloss/brx-yip-in-game-presentation.jpg"
import bloss6 from "../../assets/spiritBloss/brx-yip-vfx-presentation2.jpg"
import bloss7 from "../../assets/spiritBloss/brx-yip-vfx-sketch-presentation.jpg"
import "./pieces.css"

const Bloss = () => {
    return (
        <div className="project-wrapper">
            <div className="project-container">
                <img src={bloss5} className="project-img" alt="artwork" />
                <p className="proj-caption"> In-game Concept</p>
                <img src={bloss6} className="project-img" alt="artwork" />
                <p className="proj-caption"> VFX Exploration</p>
                <img src={bloss3} className="project-img" alt="artwork" />
                <p className="proj-caption"> Exploration sketch 2</p>
                <img src={bloss2} className="project-img" alt="artwork" />
                <p className="proj-caption"> Exploration sketch 1</p>
                <img src={bloss4} className="project-img" alt="artwork" />
                <p className="proj-caption"> More Refined version</p>
                <img src={bloss7} className="project-img" alt="artwork" />
                <p className="proj-caption"> VFX Sketches</p>
                <img src={bloss1} className="project-img" alt="artwork" />
                <p className="proj-caption"> Chroma Exploration</p>

            </div>
        </div>
    )
}

export default Bloss
