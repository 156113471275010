import light1 from "../../assets/lightWarrior/light-char-con.jpg"
import light2 from "../../assets/lightWarrior/light-char-mat.jpg"
import light3 from "../../assets/lightWarrior/brx-yip-character-process.jpg"
import light4 from "../../assets/lightWarrior/brx-yip-finreal.jpg"
import light5 from "../../assets/lightWarrior/brx-yip-spray-concepts.jpg"
import light6 from "../../assets/lightWarrior/brx-yip-weapon-concept.jpg"
import light7 from "../../assets/lightWarrior/brx-yip-weapon-materials.jpg"
import light8 from "../../assets/lightWarrior/brx-yip-weapon-process.jpg"
import "./pieces.css"

const Light = () => {
    return (
        <div className="project-wrapper">
            <div className="project-container">
                <img src={light1} className="project-img" alt="artwork" />
                <img src={light2} className="project-img" alt="artwork" />
                <img src={light3} className="project-img" alt="artwork" />
                <img src={light6} className="project-img" alt="artwork" />
                <img src={light7} className="project-img" alt="artwork" />
                <img src={light8} className="project-img" alt="artwork" />
                <img src={light5} className="project-img" alt="artwork" />
                <img src={light4} className="project-img" alt="artwork" />

            </div>
        </div>
    )
}

export default Light
