import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import SignupFormPage from "./components/SignupFormPage";
import LoginFormPage from "./components/LoginFormPage";
import { authenticate } from "./store/session";
import Navigation from "./components/Navigation";
import "./index.css"
import Home from "./components/Home";
import About from "./components/About";
import Light from "./components/Pieces";
import Bloss from "./components/Pieces/blossom";
import Bat from "./components/Pieces/batman";
import Wolf from "./components/Pieces/wolf";
import Bav from "./components/Mobile-Nav";
import Wulf from "./components/Pieces/wolf2";

function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    dispatch(authenticate()).then(() => setIsLoaded(true));
  }, [dispatch]);

  return (
    <>
      <div className="website-wrapper">
        <Bav />
        <Navigation isLoaded={isLoaded} />
        {isLoaded && (
          <Switch>
            <Route path="/warrior-of-light">
              <Light />
            </Route>
            <Route path="/spirit-blossom-zoe">
              <Bloss />
            </Route>
            <Route path="/mikael">
              <Bat />
            </Route>
            <Route path="/wolf-pt2">
              <Wulf />
            </Route>
            <Route path="/wolf">
              <Wolf />
            </Route>
            <Route path="/about" >
              <About />
            </Route>
            <Route path="/login" >
              <LoginFormPage />
            </Route>
            <Route path="/signup">
              <SignupFormPage />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        )}
      </div>
    </>
  );
}

export default App;
