import light1 from "../../assets/lightWarrior/light-char-con.jpg"
import light2 from "../../assets/lightWarrior/light-char-mat.jpg"
import light3 from "../../assets/lightWarrior/brx-yip-character-process.jpg"
import light4 from "../../assets/lightWarrior/brx-yip-finreal.jpg"
import light5 from "../../assets/lightWarrior/brx-yip-spray-concepts.jpg"
import light6 from "../../assets/lightWarrior/brx-yip-weapon-concept.jpg"
import light7 from "../../assets/lightWarrior/brx-yip-weapon-materials.jpg"
import light8 from "../../assets/lightWarrior/brx-yip-weapon-process.jpg"
import bloss1 from "../../assets/spiritBloss/brx-yip-chromas-presentation.jpg"
import bloss2 from "../../assets/spiritBloss/brx-yip-exploration-presentation-01test.jpg"
import bloss3 from "../../assets/spiritBloss/brx-yip-exploration-presentation-02.jpg"
import bloss4 from "../../assets/spiritBloss/brx-yip-hero-shot-refined-concept.jpg"
import bloss5 from "../../assets/spiritBloss/brx-yip-in-game-presentation.jpg"
import bloss6 from "../../assets/spiritBloss/brx-yip-vfx-presentation2.jpg"
import bloss7 from "../../assets/spiritBloss/brx-yip-vfx-sketch-presentation.jpg"
import wolf1 from "../../assets/wolf/brx-yip-character-sheet.jpg"
import wolf2 from "../../assets/wolf/brx-yip-clownpass.jpg"
import wolf3 from "../../assets/wolf/brx-yip-illustration.jpg"
import wolf4 from "../../assets/wolf/brx-yip-iterations-presentation.jpg"
import wolf5 from "../../assets/wolf/brx-yip-orthographic.jpg"
import bat1 from "../../assets/batman/brx-yip-1.jpg"
import bat2 from "../../assets/batman/brx-yip-2.jpg"
import bat3 from "../../assets/batman/brx-yip-3.jpg"
import bat4 from "../../assets/batman/brx-yip-ideation-sketches.jpg"
import bat5 from "../../assets/batman/brx-yip-movement-sketches.jpg"
import bat6 from "../../assets/batman/brx-yip-skins-title-illustration.jpg"
import wulf1 from "../../assets/wolf-pt2/20231024_2306_Wolf_part_2_WIP.jpg"
import wulf2 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP_1.jpg"
import wulf3 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP_2.jpg"
import wulf4 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP_3.jpg"
import wulf5 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP_4.jpg"
import wulf6 from "../../assets/wolf-pt2/20231024_2307_Wolf_part_2_WIP.jpg"
import wulf7 from "../../assets/wolf-pt2/ConceptSheet.png"
import wulf8 from "../../assets/wolf-pt2/iterations.png"
import wulf9 from "../../assets/wolf-pt2/MovementStudies.png"
import wulf10 from "../../assets/wolf-pt2/Orthographic.png"
import wulf11 from "../../assets/wolf-pt2/Pitchartpage.png"

import "./home.css";
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom/cjs/react-router-dom"

const Home = () => {

    const [project1, setProject1] = useState(false)
    const [project2, setProject2] = useState(false)
    const [project3, setProject3] = useState(false)
    const [project4, setProject4] = useState(false)
    const [project5, setProject5] = useState(false)


    const toggleProject1 = () => {
        setProject1(!project1); // Toggle the value from true to false or vice versa
        console.log("hit")
        if (!project1) {
            console.log("Ow", project1)
            setProject2(false)
            setProject3(false)
            setProject4(false)
            setProject5(false)
        }
    };

    const toggleProject2 = () => {
        setProject2(!project2); // Toggle the value from true to false or vice versa
        if (!project2) {
            setProject1(false)
            setProject3(false)
            setProject4(false)
            setProject5(false)
        }
    };

    const toggleProject3 = () => {
        setProject3(!project3); // Toggle the value from true to false or vice versa
        if (!project3) {
            setProject1(false)
            setProject2(false)
            setProject4(false)
            setProject5(false)
        }
    };

    const toggleProject4 = () => {
        setProject4(!project4); // Toggle the value from true to false or vice versa
        if (!project4) {
            setProject1(false)
            setProject2(false)
            setProject3(false)
            setProject5(false)
        }
    };

    const toggleProject5 = () => {
        setProject5(!project5); // Toggle the value from true to false or vice versa
        if (!project5) {
            setProject1(false)
            setProject2(false)
            setProject3(false)
            setProject4(false)
        }
    };

    const artworksStyle = {
        transform: `translateY(${project1 ? '3%' : '0%'})`,
        transition: 'transform 0.5s ease-out', // Adjust the duration and easing as needed
    };

    const artworksStyle2 = {
        transform: `translateY(${project2 ? '3%' : '0%'})`,
        transition: 'transform 0.5s ease-out', // Adjust the duration and easing as needed
    };

    const artworksStyle3 = {
        transform: `translateY(${project3 ? '3%' : '0%'})`,
        transition: 'transform 0.5s ease-out', // Adjust the duration and easing as needed
    };

    const artworksStyle4 = {
        transform: `translateY(${project4 ? '3%' : '0%'})`,
        transition: 'transform 0.5s ease-out', // Adjust the duration and easing as needed
    };

    const artworksStyle5 = {
        transform: `translateY(${project4 ? '3%' : '0%'})`,
        transition: 'transform 0.5s ease-out', // Adjust the duration and easing as needed
    }

    return (
        <div className="home-wrapper">
            <div className="art-comp">
                {project5 && (<NavLink exact to="/wolf-pt2" className="project-link"><header className="art-comp-header">WOLF PART 2</header></NavLink>)}
                <div className="artworks"
                    // onMouseEnter={() => setProject3(true)}
                    // onMouseLeave={() => setProject3(false)}
                    onClick={toggleProject5}
                    style={artworksStyle5}

                >
                    <div className="art-slide" >
                        <img src={wulf1} className="art-comp-img" alt="artwork" />
                        <img src={wulf2} className="art-comp-img" alt="artwork" />
                        <img src={wulf3} className="art-comp-img" alt="artwork" />
                        <img src={wulf4} className="art-comp-img" alt="artwork" />
                        <img src={wulf5} className="art-comp-img" alt="artwork" />
                        <img src={wulf6} className="art-comp-img" alt="artwork" />
                        <img src={wulf7} className="art-comp-img" alt="artwork" />
                        <img src={wulf8} className="art-comp-img" alt="artwork" />
                        <img src={wulf9} className="art-comp-img" alt="artwork" />
                        <img src={wulf10} className="art-comp-img" alt="artwork" />
                        <img src={wulf11} className="art-comp-img" alt="artwork" />

                    </div>
                    <div className="art-slide" >
                        <img src={wulf1} className="art-comp-img" alt="artwork" />
                        <img src={wulf2} className="art-comp-img" alt="artwork" />
                        <img src={wulf3} className="art-comp-img" alt="artwork" />
                        <img src={wulf4} className="art-comp-img" alt="artwork" />
                        <img src={wulf5} className="art-comp-img" alt="artwork" />
                        <img src={wulf6} className="art-comp-img" alt="artwork" />
                        <img src={wulf7} className="art-comp-img" alt="artwork" />
                        <img src={wulf8} className="art-comp-img" alt="artwork" />
                        <img src={wulf9} className="art-comp-img" alt="artwork" />
                        <img src={wulf10} className="art-comp-img" alt="artwork" />
                        <img src={wulf11} className="art-comp-img" alt="artwork" />
                    </div>

                </div>
                {project3 && (<NavLink exact to="/wolf" className="project-link"><header className="art-comp-header">WOLF PART 1</header></NavLink>)}
                <div className="artworks"
                    // onMouseEnter={() => setProject3(true)}
                    // onMouseLeave={() => setProject3(false)}
                    onClick={toggleProject3}
                    style={artworksStyle3}

                >
                    <div className="art-slide" >
                        <img src={wolf1} className="art-comp-img" alt="artwork" />
                        <img src={wolf2} className="art-comp-img" alt="artwork" />
                        <img src={wolf3} className="art-comp-img" alt="artwork" />
                        <img src={wolf4} className="art-comp-img" alt="artwork" />
                        <img src={wolf5} className="art-comp-img" alt="artwork" />


                    </div>
                    <div className="art-slide" >
                        <img src={wolf1} className="art-comp-img" alt="artwork" />
                        <img src={wolf2} className="art-comp-img" alt="artwork" />
                        <img src={wolf3} className="art-comp-img" alt="artwork" />
                        <img src={wolf4} className="art-comp-img" alt="artwork" />
                        <img src={wolf5} className="art-comp-img" alt="artwork" />
                    </div>

                </div>
                {project1 && (<NavLink exact to="/warrior-of-light" className="project-link"><header className="art-comp-header">WARRIOR OF LIGHT</header></NavLink>)}
                <div className="artworks"
                    onClick={toggleProject1}
                    style={artworksStyle}

                >
                    <div className="art-slide" >
                        <img src={light1} className="art-comp-img" alt="artwork" />
                        <img src={light2} className="art-comp-img" alt="artwork" />
                        <img src={light3} className="art-comp-img" alt="artwork" />
                        <img src={light4} className="art-comp-img" alt="artwork" />
                        <img src={light5} className="art-comp-img" alt="artwork" />
                        <img src={light6} className="art-comp-img" alt="artwork" />
                        <img src={light7} className="art-comp-img" alt="artwork" />
                        <img src={light8} className="art-comp-img" alt="artwork" />
                    </div>
                    <div className="art-slide" >
                        <img src={light1} className="art-comp-img" alt="artwork" />
                        <img src={light2} className="art-comp-img" alt="artwork" />
                        <img src={light3} className="art-comp-img" alt="artwork" />
                        <img src={light4} className="art-comp-img" alt="artwork" />
                        <img src={light5} className="art-comp-img" alt="artwork" />
                        <img src={light6} className="art-comp-img" alt="artwork" />
                        <img src={light7} className="art-comp-img" alt="artwork" />
                        <img src={light8} className="art-comp-img" alt="artwork" />
                    </div>
                </div>

                {project2 && (<NavLink exact to="/spirit-blossom-zoe" className="project-link"><header className="art-comp-header">SPIRIT BLOSSOM ZOE</header></NavLink>)}
                <div className="artworks"
                    onClick={toggleProject2}
                    style={artworksStyle2}

                >
                    <div className="art-slide" >
                        <img src={bloss1} className="art-comp-img" alt="artwork" />
                        <img src={bloss2} className="art-comp-img" alt="artwork" />
                        <img src={bloss3} className="art-comp-img" alt="artwork" />
                        <img src={bloss4} className="art-comp-img" alt="artwork" />
                        <img src={bloss5} className="art-comp-img" alt="artwork" />
                        <img src={bloss6} className="art-comp-img" alt="artwork" />
                        <img src={bloss7} className="art-comp-img" alt="artwork" />

                    </div>
                    <div className="art-slide" >
                        <img src={bloss1} className="art-comp-img" alt="artwork" />
                        <img src={bloss2} className="art-comp-img" alt="artwork" />
                        <img src={bloss3} className="art-comp-img" alt="artwork" />
                        <img src={bloss4} className="art-comp-img" alt="artwork" />
                        <img src={bloss5} className="art-comp-img" alt="artwork" />
                        <img src={bloss6} className="art-comp-img" alt="artwork" />
                        <img src={bloss7} className="art-comp-img" alt="artwork" />
                    </div>

                </div>


                {project4 && (<NavLink exact to="/mikael" className="project-link"><header className="art-comp-header">MIKAEL - THE BLADE OF DEMACIA</header></NavLink>)}
                <div className="artworks"
                    // onMouseEnter={() => setProject3(true)}
                    // onMouseLeave={() => setProject3(false)}
                    onClick={toggleProject4}
                    style={artworksStyle4}

                >
                    <div className="art-slide" >
                        <img src={bat1} className="art-comp-img" alt="artwork" />
                        <img src={bat2} className="art-comp-img" alt="artwork" />
                        <img src={bat3} className="art-comp-img" alt="artwork" />
                        <img src={bat4} className="art-comp-img" alt="artwork" />
                        <img src={bat5} className="art-comp-img" alt="artwork" />
                        <img src={bat6} className="art-comp-img" alt="artwork" />


                    </div>
                    <div className="art-slide" >
                        <img src={bat1} className="art-comp-img" alt="artwork" />
                        <img src={bat2} className="art-comp-img" alt="artwork" />
                        <img src={bat3} className="art-comp-img" alt="artwork" />
                        <img src={bat4} className="art-comp-img" alt="artwork" />
                        <img src={bat5} className="art-comp-img" alt="artwork" />
                        <img src={bat6} className="art-comp-img" alt="artwork" />
                    </div>

                </div>

            </div>
        </div>
    );
};

export default Home;


// const [currentIndex, setCurrentIndex] = useState(0);

// const handleCarouselHover = () => {
//     // Increment the currentIndex to simulate the scrolling effect
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % artArray.length);
// };

// useEffect(() => {
//     // Set up a timer to automatically advance the carousel
//     const timer = setInterval(handleCarouselHover, 2000); // Change the delay as needed
//     return () => clearInterval(timer);
// }, []);

// {artArray.map((art, i) => (
//     // <li
//     //     className="art-comp-list-ele"
//     //     key={i}
//     // >
//     <img src={art} className="art-comp-img" alt="artwork" />

// ))}
