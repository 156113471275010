import bat1 from "../../assets/batman/brx-yip-1.jpg"
import bat2 from "../../assets/batman/brx-yip-2.jpg"
import bat3 from "../../assets/batman/brx-yip-3.jpg"
import bat4 from "../../assets/batman/brx-yip-ideation-sketches.jpg"
import bat5 from "../../assets/batman/brx-yip-movement-sketches.jpg"
import bat6 from "../../assets/batman/brx-yip-skins-title-illustration.jpg"
import "./pieces.css"

const Bat = () => {
    return (
        <div className="project-wrapper">
            <div className="project-container">
                <img src={bat6} className="project-img" alt="artwork" />


                <img src={bat3} className="project-img" alt="artwork" />
                <p className="proj-caption"> FINAL Design</p>
                <img src={bat5} className="project-img" alt="artwork" />

                <p className="proj-caption"> Movement Sketches</p>


                <img src={bat4} className="project-img" alt="artwork" />
                <p className="proj-caption"> Exploration Sketches</p>
                <img src={bat1} className="project-img" alt="artwork" />
                <p className="proj-caption"> Maurauder Concept</p>
                <img src={bat2} className="project-img" alt="artwork" />
                <p className="proj-caption"> Blood Moon Concept</p>


            </div>
        </div>
    )
}

export default Bat
